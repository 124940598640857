import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchUserData = createAsyncThunk(
  'userData/fetchUserData',
  async () => {
    try {
      const response = await axios.get(
        'https://mentordproductionapi.azurewebsites.net/api/people/get'
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
  }
);

const initialState = {
  data: [],
  categories: [],
  usersByCategory: {},
  status: 'idle',
  error: null,
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        if (Array.isArray(action.payload)) {
          state.categories = [
            ...new Set(action.payload.map((mentor) => mentor.industry)),
          ];

          state.usersByCategory = action.payload.reduce((acc, user) => {
            if (!acc[user.industry]) {
              acc[user.industry] = [];
            }
            acc[user.industry].push(user);
            return acc;
          }, {});
        }
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default userDataSlice.reducer;
