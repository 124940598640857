import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import useAuthorization from '../common/useAuthorization';


const ProtectedRoute = ({ element: Component, authorizedRoles, ...rest }) => {
  const { isAuthorized } = useAuthorization(); // Get user's authorization status from hook

  return isAuthorized([authorizedRoles]) ? (
    <Route {...rest} element={<Component />} />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoute;
