import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {
    allUsers: [],
    techUsers: [],
    nonTechUsers: [],
  },
  updatedUserData: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData.allUsers = action.payload;
    },
    
    updateUserData: (state, action) => {
      state.updatedUserData = action.payload;
    },

    // Add the logoutUser action
    logoutUser: (state) => {
      // Reset state to initial values
      state.userData = {
        allUsers: [],
        techUsers: [],
        nonTechUsers: [],
      };
      state.updatedUserData = {};
    },
  },
});

export const { setUser, updateUserData, logoutUser } = userSlice.actions;

export default userSlice.reducer;