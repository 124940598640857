import React from "react";

const Button = ({ children, onClick, className }) => {
    return (
        <button
            className={` text-gray-900 border border-gray-300 hover:text-gray-700 hover:border-gray-400 ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
