import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
   
  </React.StrictMode>
);


// import React, { createContext, useContext } from 'react';
// import { AppContextProvider } from './components/appContext/appContext';
// // import { AppProvider } from './app/AppProvider';

// export const CombineContext = createContext();

// export default function CombineContextProvider({ children }) {
//   return <AppContextProvider>{children}</AppContextProvider>;
// }

// export function useCombineContext() {
//   return useContext(CombineContext);
// }
