import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAvailabilityData = createAsyncThunk(
  'availability/fetchAvailabilityData',
  async (mentorId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://mentordproductionapi.azurewebsites.net/api/mentor/GetAvailabilityPeriodByMentorId/${mentorId}`
      );
    
      return response?.data; // Return the fetched data
    } catch (error) {
      
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: [],
  status: 'idle',
  error: null,
  selectedDate: null,
  selectedTime: null,
  selectedEndTime: null,
};
const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    updateSelectedDate: (state, action) => {
      state.selectedDate = action?.payload;
    },
    updateSelectedTime: (state, action) => {
      state.selectedTime = action?.payload;
    },
    updateSelectedEndTime: (state, action) => {
      state.selectedEndTime = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAvailabilityData?.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAvailabilityData?.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; // Update data with the fetched availability data
      })
      .addCase(fetchAvailabilityData?.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { updateSelectedDate, updateSelectedTime, updateSelectedEndTime } =
  availabilitySlice.actions;

export default availabilitySlice?.reducer;