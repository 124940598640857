import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async action to fetch pricing data
export const fetchPricingData = createAsyncThunk(
  'pricing/fetchPricingData',
  async (mentorId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://mentordproductionapi.azurewebsites.net/api/price-description/GetPriceByMentorId/${mentorId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {}, // Store pricing data here
  status: 'idle', // Track loading state (idle/loading/succeeded/failed)
  error: null, // Store errors if any
};

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    // Reducer to clear the pricing data
    clearPricingData(state) {
      state.data = {}; // Clear the data
      state.status = 'idle'; // Reset status
      state.error = null; // Clear any previous error
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricingData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPricingData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPricingData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Export the clearPricingData action
export const { clearPricingData } = pricingSlice.actions;

// Export the reducer as the default export
export default pricingSlice.reducer;
