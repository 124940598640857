import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signInUser: null,
  signUpUser: null,
};

const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setSignInUser: (state, action) => {
      state.signInUser = action.payload;
    },
    setSignUpUser: (state, action) => {
      state.signUpUser = action.payload;
    },
  },
});

export const { setSignInUser, setSignUpUser } = signInSlice.actions;

export default signInSlice.reducer;
