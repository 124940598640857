import React from 'react';
import Logo from '../../images/logo/img1.jpeg';

export default function Spinner() {
  return (
    <div className="relative">
      <div
        className="inline-block h-16 w-16 animate-spin rounded-full border-6 border-solid border-current border-r-transparent align-middle motion-reduce:animate-spin-[2.9s_linear_infinite]"
        role="status">
        <img src={Logo} alt="logo" className="w-16 h-16 rounded-full inset-0 m-auto" />
      </div>
    </div>
  );
}
