import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "./HomeButton";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./utils/spinner";

function AvailabilityPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const updatedUserData = useSelector((state) => state?.user?.updatedUserData);
  const newUserId = updatedUserData?.allUsers?.mentorId;

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setStartTime(undefined);
    setEndTime(undefined);
  };

  // Generate time options with 30-minute intervals
  const generateTimeOptions = () => {
    let times = [];
    const currentDateTime = new Date();
    const selectedDateTime = new Date(selectedDate);

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
        const formattedMinute = minute === 0 ? "00" : "30";
        const timeString = `${formattedHour}:${formattedMinute}`;

        // Create a Date object for comparison
        const timeDateTime = new Date(
          `${selectedDateTime.toDateString()} ${timeString}`
        );

        // Only add future time options
        if (timeDateTime > currentDateTime) {
          times.push(timeString);
        }
      }
    }
    return times;
  };

  const timeOptions = generateTimeOptions();

  const handleSubmit = async () => {
    if (startTime >= endTime) {
      toast.error("End time must be greater than start time");
      return;
    }

    const selectedDateTime = new Date(selectedDate);
    const startDateTime = new Date(
      `${selectedDateTime.toDateString()} ${startTime}`
    );
    const endDateTime = new Date(
      `${selectedDateTime.toDateString()} ${endTime}`
    );

    if (startDateTime.getTime() === endDateTime.getTime()) {
      toast.error("Start time and end time cannot be the same");
      return;
    }

    setIsLoading(true);
    try {
      const formattedDate = selectedDate?.toISOString().slice(0, 10);
      const url =
        "https://mentordproductionapi.azurewebsites.net/api/mentor/CreateAvailabilityPeriod";
      const data = {
        date: formattedDate,
        startTime,
        endTime,
        mentorId: newUserId,
      };
      const response = await axios.post(url, data);
      if (response?.status === 200 || response?.status === 201) {
        setIsOpen(false);
        toast.success("Availability updated successfully");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "You have set a Schedule for the selected date, Please kindly reschedule for another date."
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Reset availability if the time has passed
  useEffect(() => {
    const checkAvailability = () => {
      const currentTime = new Date();
      const selectedDateTime = new Date(selectedDate);
      const startDateTime = new Date(
        `${selectedDateTime.toDateString()} ${startTime}`
      );
      const endDateTime = new Date(
        `${selectedDateTime.toDateString()} ${endTime}`
      );

      if (currentTime > endDateTime) {
        setStartTime(undefined);
        setEndTime(undefined);
        toast.info("Your availability has expired and has been reset.");
      }
    };

    // Check availability every minute
    const interval = setInterval(checkAvailability, 60000);
    return () => clearInterval(interval);
  }, [selectedDate, startTime, endTime]);

  return (
    <div className="flex items-center justify-center">
      <Button
        onClick={handleOpenPopup}
        className="w-56 md:w-[300px] xl:w-[500px] font-semibold rounded-full h-12 bg-gradient-to-r from-red-800 via-red-700 to-black hover:bg-gradient-to-l hover:text-white text-white"
      >
        Set Availability
      </Button>

      {isOpen && (
        <div className="fixed top-0 left-0 z-50 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
          <div className="relative p-6 w-full max-w-md bg-white rounded-lg shadow-lg">
            <h2 className="text-center text-2xl font-bold text-gray-900 mb-6">
              Edit Availability
            </h2>
            <p className="text-center text-gray-700 mb-6">
              Set the time period when Mentees can schedule sessions
            </p>

            <div className="flex flex-col items-center mb-4">
              <p className="text-lg font-medium">Select Date:</p>
              <div className="relative mt-2">
                <DatePicker
                  selected={selectedDate}
                  onChange={setSelectedDate}
                  dateFormat="MMMM d, yyyy"
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  minDate={new Date()} // This prevents selection of past dates
                />

                {selectedDate && (
                  <button
                    onClick={() => setSelectedDate(new Date())}
                    className="absolute top-0 right-0 px-3 py-2 bg-red-500 text-white rounded-r-lg hover:bg-red-700"
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>

            <div className="flex justify-between gap-4 mb-4">
              <div>
                <p className="font-medium mb-2">Start Time</p>
                <select
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select Start Time
                  </option>
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <p className="font-medium mb-2">End Time</p>
                <select
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="" disabled>
                    Select End Time
                  </option>
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-between items-center mt-6">
              <button
                onClick={handleSubmit}
                className="px-6 py-2 bg-gradient-to-r from-red-800 via-red-700 to-black text-white rounded-lg hover:bg-gradient-to-l transition"
              >
                Save Changes
              </button>
              <button
                onClick={handleClosePopup}
                className="px-6 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700 transition"
              >
                Close
              </button>
            </div>

            {isLoading && (
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
                <Spinner />
                <p className="text-white mt-4">Please wait...</p>
              </div>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default AvailabilityPopup;
