import { createSlice } from '@reduxjs/toolkit';

export const menteeSlice = createSlice({
  name: 'mentee',
  initialState: {
    menteeResponse: null,
  },
  reducers: {
    setMenteeResponse: (state, action) => {
      state.menteeResponse = action.payload;
    },
  },
});

export const { setMenteeResponse } = menteeSlice.actions;

export default menteeSlice.reducer;
