import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signInUser: null,
  error: null,
};

export const signInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setSignInUser: (state, action) => {
      state.signInUser = action.payload;
      state.error = null; // Reset error state on successful sign-in
    },
    signOutUser: (state) => {
      state.signInUser = null;
      state.error = null; // Reset error state on sign-out
    },
    setSignInError: (state, action) => {
      state.error = action.payload; // Set error state with the provided error message
    },
    clearSignInError: (state) => {
      state.error = null; // Clear error state
    },
  },
});

export const { setSignInUser, signOutUser, setSignInError, clearSignInError } =
  signInSlice.actions;

export default signInSlice.reducer;
