import { useMemo } from 'react';
import { getSavedUserRole } from '../Utility/auth';

const useAuthorization = () => {
  const userRole = useMemo(() => getSavedUserRole(), []); // Get user's role from hook

  const isAuthorized = (authorizedRoles) => {
    return authorizedRoles?.includes(userRole);
  };

  return { isAuthorized, userRole }; // Return user's role from the hook
};

export default useAuthorization;
