import { isExpired } from 'react-jwt';
import crypto from 'crypto-js';

export const encodeToken = (role, token) => {
  const encodedToken = crypto.AES.encrypt(
    JSON.stringify({ token }),
    process.env.REACT_APP_JWT_SECRET
  ).toString();
  window.localStorage.setItem(role, encodedToken);
   
};

export const decodeToken = (name) => {
  const encodedToken = window.localStorage.getItem(name);
  if (encodedToken) {
    const decodedToken = crypto.AES.decrypt(
      encodedToken,
      process.env.REACT_APP_JWT_SECRET
    ).toString(crypto.enc.Utf8);
   
    return JSON.parse(decodedToken).token;
  }
   
  return '';
};

export function getSavedUserRole() {
  if (window !== 'undefined') {
    const role = decodeToken('r');
  
    return role;
  }
  return null; // Return null if window is undefined or role is not found
}

export function saveUserToken(token) {
  encodeToken('t', token);
}

export function getSavedUserToken() {
  const decodedToken = decodeToken('t');
  if (decodedToken && !isExpired(decodedToken)) {
    return decodedToken;
  }
  clearUserToken();
  return '';
}

export function clearUserToken() {
  window.localStorage.clear();
}

export function saveUserName(name) {
  encodeToken('n', name);
}

export function saveUserRole(role) {
  encodeToken('r', role);
}

export function getSavedUserName() {
  return decodeToken('n');
}

export const saveUser = (user) => {
  saveUserToken(user.token);
  saveUserName(user.userName);
  saveUserRole(user.role);
};

export const logOut = () => {
  clearUserToken();
  window.location.assign('/login');
};
