import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './userSlice';
import userDataReducer from './userDataSlice';
import pricingReducer from './userPriceSlice';
import availabilityReducer from './availabilitySlice';
import menteeReducer from './menteeSlice';
import signInReducer from './signInSlice'; // Import the new signIn reducer
import { configureStore } from '@reduxjs/toolkit';
import persistStore from 'redux-persist/es/persistStore';
import setUpUserSlice from './setUpUserSlice';
import setUpMentorSlice from './setUpMenotrUserSlice'

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  userData: userDataReducer,
  pricing: pricingReducer,
  availability: availabilityReducer,
  mentee: menteeReducer,
  signIn: signInReducer, // Include the new signIn reducer in the root reducer
  setUpUser: setUpUserSlice,
  setUpMentorUser: setUpMentorSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);
