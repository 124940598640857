import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import 'react-responsive-modal/styles.css';
import { ToastContainer } from 'react-toastify';
import { AppContextProvider } from './components/appContext/appContext';
import Spinner from './components/utils/spinner';
// import ErrorBoundary from './components/hocs/ErrorBoundary';
import ProtectedRoute from './components/ProtectedRoute';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import AvailabilityPopup from './components/AvailabilityPopup';

const ErrorPage = lazy(() => import('./components/ErrorPage'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const MenteeForm = lazy(() => import('./components/pages/MenteeForm'));
const Invoice = lazy(() => import('./components/pages/Invoice'));
const LandPage = lazy(() => import('./components/landingPage'));
const Homepage = lazy(() => import('./components/pages/Homepage'));
const Form = lazy(() => import('./components/pages/Form'));
const Dashboard = lazy(() => import('./components/pages/Dashboard'));
const FinalizingAcc = lazy(() => import('./components/pages/FinalizingAcc'));
const EditProfile = lazy(() => import('./components/pages/EditProfile'));
const EditMenteeFormss = lazy(() => import('./components/EditMenteeForm'));
const Signup = lazy(() => import('./components/pages/Signup'));
const RegisterPage = lazy(() => import('./components/pages/RegisterPage'));
const Ment = lazy(() => import('./components/pages/MentePage'));
const NonTech = lazy(() => import('./components/pages/NonTech'));
const MenteeDasboard = lazy(() => import('./components/pages/MenteeDasboard'));
const MentFinalDashboard = lazy(() =>
  import('./components/pages/MentFinalDashboard')
);
const Schedule = lazy(() => import('./components/pages/Schedule'));
const Administration = lazy(() => import('./components/pages/administration'));
const MenteeReport = lazy(() =>
  import('./components/AdminReport/MenteeReport')
);
const MentorReport = lazy(() =>
  import('./components/AdminReport/MentorReport')
);

Bugsnag.start({
  apiKey: '143d4c86d148654bf83516a30854409d',
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: '143d4c86d148654bf83516a30854409d' });

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
function App() {
  return (
    <Suspense
      fallback={
        <div className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-gray-300 bg-opacity-75">
          <div className="flex flex-col items-center">
            <Spinner />
            <p className="text-red-800">Please wait</p>
          </div>
        </div>
      }>
      <Router>
        <AppContextProvider>
          <ReactNotifications />
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<LandPage />} />
              <Route path="/available" element={<AvailabilityPopup />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/menteeform" element={<MenteeForm />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/homepage" element={<Homepage />} />
              <Route path="/MenteeReport" element={<MenteeReport />} />
              <Route path="/MentorReport" element={<MentorReport />} />
              <Route path="/men" element={<Ment />} />
              <Route path="/finalizing" element={<FinalizingAcc />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/editprofile" element={<EditProfile />} />
              <Route path="/editmenteeprofile" element={<EditMenteeFormss />} />
              <Route path="/nontech" element={<NonTech />} />
              <Route path="/menteedashboard" element={<MenteeDasboard />} />
              <Route
                path="/MenteeFinalDashboard"
                element={<MentFinalDashboard />}
              />
              <Route path="/Schedule" element={<Schedule />} />
              <Route path="/administration" element={<Administration />} />
              <Route path="/form" element={<Form />} />
              <Route element={<ProtectedRoute authorizedRoles={1} />}></Route>
              <Route element={<ProtectedRoute authorizedRoles={3} />}>
                <Route path="/menteeform" element={<MenteeForm />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/homepage" element={<Homepage />} />
              </Route>
              <Route element={<ProtectedRoute authorizedRoles={2} />}>
                <Route path="/homepage" element={<Homepage />} />
              </Route>
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </ErrorBoundary>
        </AppContextProvider>
        <ToastContainer position="top-right" autoClose={3000} />
      </Router>
    </Suspense>
  );
}

export default App;
