import React, { createContext, useContext, useEffect, useState } from 'react';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userId, setUserId] = useState(() => {
    const storedUserId = localStorage.getItem('userId');
    return storedUserId ? JSON.parse(storedUserId) : '';
  });
 const [uploadedImage, setUploadedImageState] = useState(() => {
   const storedUploadedImage = localStorage.getItem('uploadedImage');
   return storedUploadedImage ? JSON.parse(storedUploadedImage) : '';
 });

  useEffect(() => {
    // Set userId and uploadedImage from localStorage when the component mounts
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(JSON.parse(storedUserId));
    }

    const storedUploadedImage = localStorage.getItem('uploadedImage');
    if (storedUploadedImage) {
      setUploadedImageState(JSON.parse(storedUploadedImage));
    }
  }, []);

  const setUploadedImage = (value) => {
    setUploadedImageState(value);
    localStorage.setItem('uploadedImage', JSON.stringify(value));
  };

  const [pictureUrl, setPictureUrl] = useState(null);

  const setPictureUrlToBlobStorage = (url) => {
    // Store the picture URL to Azure Blob Storage
    // Example: axios.post('your-blob-storage-url', { url })
    setPictureUrl(url);
  };

  return (
    <AppContext.Provider
      value={{
        userId,
        setUserId,
        uploadedImage,
        setUploadedImage,
        pictureUrl,
        setPictureUrlToBlobStorage,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
// import React, { createContext, useContext, useState } from 'react';

// // Create the context
// const AppContext = createContext();

// // Create the context provider
// // Create the context provider
// export const AppContextProvider = ({ children, initialUserId, initialUploadedImage, initialPictureUrl }) => {
//   // State variables
//   const [userId, setUserId] = useState(initialUserId || '');
//   const [uploadedImage, setUploadedImage] = useState(initialUploadedImage || '');
//   const [pictureUrl, setPictureUrl] = useState(initialPictureUrl || null);

//   // Function to set the uploaded image
//   const handleUploadedImage = (value) => {
//     setUploadedImage(value);
//     // Additional logic for handling image upload if needed
//   };

//   // Function to set the picture URL
//   const handlePictureUrl = (url) => {
//     setPictureUrl(url);
//     // Additional logic for handling picture URL if needed
//   };

//   return (
//     <AppContext.Provider
//       value={{
//         userId,
//         setUserId,
//         uploadedImage,
//         handleUploadedImage,
//         pictureUrl,
//         handlePictureUrl,
//       }}
//     >
//       {children}
//     </AppContext.Provider>
//   );
// };


// // Create a custom hook to use the context
// export const useAppContext = () => useContext(AppContext);
